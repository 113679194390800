import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  MobileStepper,
  Container
} from "@mui/material";
import { useAuth } from "context/AuthContext";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Logo from "app/components/layout/Logo";

const steps = 3;

const IntroFormPage = () => {
  const [activeStep, setActiveStep] = React.useState(1);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNext = () => {
    if (activeStep < steps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      navigate("/match");
    }
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      navigate("/app");
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ px: 0 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Logo />
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleLogout}
          sx={{ borderRadius: 8, px: 2, py: 1 }}
        >
          Logout
        </Button>
      </Box>
      <Box
        sx={{
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 3,
        }}
      >
        <Box sx={{ mt: 4 }}>
          {activeStep === 1 && (
            <>
              <Typography variant="h2" fontWeight={600} gutterBottom>
                How should we call you?
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Your name"
                InputProps={{
                  style: {
                    backgroundColor: "#f5f5f5",
                    borderRadius: 12,
                    padding: "10px 12px",
                    fontSize: "1.1rem",
                  },
                }}
                sx={{ mt: 2, mb: 6 }}
              />
            </>
          )}
          {activeStep === 2 && (
            <>
              <Typography variant="h2" fontWeight={600} gutterBottom>
                Introduce yourself
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                mb={2}
                fontWeight={400}
              >
                Your answer will be used to generate interesting ice breaking
                questions
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                placeholder="You can talk about your work, area of study, or passion"
                InputProps={{
                  style: {
                    backgroundColor: "#f5f5f5",
                    borderRadius: 12,
                    padding: "10px 12px",
                    fontSize: "1.1rem",
                  },
                }}
                sx={{ mt: 2, mb: 6 }}
              />
            </>
          )}
        </Box>

        {/* Stepper */}
        <MobileStepper
          variant="progress"
          steps={steps}
          position="static"
          color="secondary"
          activeStep={activeStep}
          sx={{
            mt: 2,
            justifyContent: "space-between",
            bgcolor: "transparent",
            ".MuiMobileStepper-progress": {
              width: { xs: "50%", sm: "85%" },
              bgcolor: "#e0e0e0",
              height: 4,
              borderRadius: 2,
            },
          }}
          backButton={
            <Button
              size="large"
              onClick={handleBack}
              color="secondary"
              variant="contained"
              sx={{ mr: 2 }}
              startIcon={
                <Icon icon="akar-icons:arrow-left" width={20} height={20} />
              }
            >
              Back
            </Button>
          }
          nextButton={
            <Button
              size="large"
              variant="contained"
              onClick={handleNext}
              color="secondary"
              disabled={activeStep === steps - 1 && activeStep === 0}
              sx={{ ml: 1 }}
              endIcon={
                <Icon icon="akar-icons:arrow-right" width={20} height={20} />
              }
            >
              Next
            </Button>
          }
        />
      </Box>
    </Container>
  );
};

export default IntroFormPage;
