import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const cardData = [
  {
    title: "Target Users",
    headline: "Individuals Seeking Self-Improvement",
    content:
      "SPARK Journaling is designed for students, professionals, and individuals committed to self-improvement, emotional resilience, and building healthier mindsets. It is especially beneficial for those facing challenges with self-criticism, mindfulness, and fostering pro-social behaviors.",
  },
  {
    title: "The Challenge",
    headline: "Overcoming Negative Self-Talk",
    content:
      "With 80% of daily thoughts being negative and 95% repetitive, many individuals are trapped in a cycle of self-criticism and limited self-awareness. This not only affects emotional well-being but also hinders the development of self-compassion, awareness, and social connectedness.",
  },
  {
    title: "Our Solution",
    headline: "SPARK Journaling",
    content:
      "SPARK Journaling combines seamless writing and advanced AI capabilities to help users reflect and grow. With features like handwriting and OCR integration, multi-lingual support, privacy-focused AI co-editing, and real-time feedback, it enables users to cultivate self-compassion, kindness, and meaningful reflection for lasting positive change.",
  },
];

const DemoTitle = () => {
  const handleLearnMoreClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSf1pL3xSv52RRPVa5Eo9sJCL396f6_r7157VO_0a-37QDUzrQ/viewform?usp=sf_link",
      "_blank"
    );
  };

  return (
    <Box sx={{ padding: { xs: 2, md: 6 } }}>
      <Grid container spacing={2} justifyContent="center">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Card sx={{ height: "100%", boxShadow: "none" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  color="primary"
                  gutterBottom
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="h2"
                  color="secondary"
                  sx={{
                    height: {
                      xs: 120,
                      sm: 100,
                    },
                  }}
                >
                  {card.headline}
                </Typography>
                <Typography variant="body1">{card.content}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box textAlign="center" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLearnMoreClick}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default DemoTitle;
