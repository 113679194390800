import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { LinearProgress, Box } from "@mui/material";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <LinearProgress />
      </Box>
    );
  }

  return user ? children : <Navigate to="/auth/login" replace />;
};

export default PrivateRoute;
